<template>
  <div class="form-container step-one">
    <q-form class="q-gutter-md">
      <div class="form-header">
        <div>Créer un modèle</div>
        <p>Pour créer un modèle, suivez ces quelques étapes :</p>
      </div>

      <div class="steps-wrapper">
        <div class="step-item">
          <div class="title">Étape 1</div>
          <img src="../../assets/icons/new-template-upload-cloud.svg" />
          <p>
            Importez votre document Word ou Excel que vous souhaitez utiliser comme modèle
          </p>
        </div>
        <div class="step-item step-item-middle">
          <div class="title">Étape 2</div>
          <img src="../../assets/icons/new-template-file-plus.svg" />
          <p>
            Ajoutez des entités et des informations et placez les dans le modèle
          </p>
        </div>
        <div class="step-item">
          <div class="title">Étape 3</div>
          <img src="../../assets/icons/new-template-save.svg" />
          <p>
            Enregistrez votre modèle afin de pouvoir l’utiliser lors de la création de vos futurs documents
          </p>
        </div>
      </div>

      <div class="bottom-buttons">
        <input
          type="file"
          ref="fileUpload"
          v-on:change="onFileUpload"
          accept=".docx,.doc,.xlsx,.xls"
          style="position:fixed; left:-100em"
        />
        <q-btn
          label="Importer le document de référence"
          type="button"
          :loading="loading"
          @click="insertImageButtonClickHandler"
        >
          <q-icon class="upload-icon" />
        </q-btn>
      </div>
    </q-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewTemplateStepOne',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('documents', [
      'getSendingModel',
      'getErrorSendingModel',
      'getModel',
      'getLoadingModel'
    ])
  },
  methods: {
    ...mapActions('documents', ['sendModel', 'uploadModelToS3', 'fetchModel']),
    insertImageButtonClickHandler: function() {
      this.$refs.fileUpload.click()
    },
    onFileUpload: async function(e) {
      this.loading = true
      const data = {
        modelFileName: e.target.files[0].name
      }

      const s3UrlObject = await this.sendModel({ data })
      await this.uploadModelToS3({
        s3UrlObject,
        file: e.target.files[0],
        type: e.target.files[0].type
      })

      const interval = setInterval(async () => {
        const response = await this.fetchModel({ modelId: s3UrlObject.modelId })
        if (
          response.modelStatus === 'READY' ||
          response.modelStatus === 'FIRST_UPLOAD'
        ) {
          clearInterval(interval)
          this.$router.push(`/edit-template/${response.modelId}`)
        }
      }, 1000)
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  watch: {
    getErrorSendingModel: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
