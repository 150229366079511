<template>
  <div class="new-template">
    <q-page-container>
      <q-page>
        <StepOne v-if="currentStep === 1" @selectedFile="selectedFile" />
        <StepTwo v-if="currentStep === 2" :file.sync="file" />
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import StepOne from '@/components/new-template/StepOne.vue'
import StepTwo from '@/components/new-template/StepTwo.vue'

export default {
  name: 'NewTemplate',
  components: {
    StepTwo,
    StepOne
  },
  data() {
    return {
      currentStep: 1,
      file: null
    }
  },
  created() {
    this.$store.commit('templates/setDocumentType', 'template')
  },
  methods: {
    selectedFile({ file }) {
      this.file = file
      this.currentStep = 2
    }
  }
}
</script>
